/* eslint-disable react/jsx-no-target-blank */
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
//import {CheckAccess} from '../../../../app/Utilities';

export function AsideMenuMainGP() {
  return (
    <>
      {
        <AsideMenuItem
          to='/instrument/dashboard'
          icon='/media/icons/duotone/Home/Home.svg'
          title='Tableau de bord'
          fontIcon='bi-home'
          method={'dashboard-access'}
        />
      }
      {
        //CheckAccess("department-access") &&
        <AsideMenuItem
          to='/instrument/departments'
          icon='/media/icons/duotone/Home/Building.svg'
          title='Départements'
          fontIcon='bi-building'
          method={'department-access'}
        />
      }
      {
        //CheckAccess("material-access") &&
        <AsideMenuItemWithSub
          to='/instrument/materials'
          title='Matériels'
          icon='/media/icons/duotone/Tools/Tools.svg'
          fontIcon='bi-tools'
          method={'material-access'}
        >
          <AsideMenuItem
            to='/instrument/materials/lists'
            title='Liste des matériels'
            hasBullet={true}
            method={'material-access'}
          />
          {
            //CheckAccess("material-add") &&
            <AsideMenuItem
              to='/instrument/materials/add'
              title='Ajouter un matériel'
              hasBullet={true}
              method={'material-add'}
            />
          }
          {
            //CheckAccess("outback-access") &&
            <AsideMenuItem
              to='/instrument/materials/out-back'
              title='Sortie/Retour de matériels'
              hasBullet={true}
              method={'outback-access'}
            />
          }
          {
            //CheckAccess("quarantine-register-access") &&
            <AsideMenuItem
              to='/instrument/materials/quarantine-register'
              title='Registre de quarantaine'
              hasBullet={true}
              method={'quarantine-register-access'}
            />
          }
        </AsideMenuItemWithSub>
      }
      {
        //CheckAccess("program-access") &&
        <AsideMenuItemWithSub
          to='/instrument/programmes'
          title='Programmes'
          icon='/media/icons/duotone/Interface/Calendar.svg'
          fontIcon='bi-tools'
          method={'program-access'}
        >
          {
            //CheckAccess("program-etalonnage-access") &&
            <AsideMenuItem
              to='/instrument/programmes/raccordements'
              title='Prog. de raccordement'
              hasBullet={true}
              method={'program-etalonnage-access'}
            />
          }
          {
            //CheckAccess("program-verification-access") &&
            <AsideMenuItem
              to='/instrument/programmes/verifications'
              title='Prog. de vérification interne'
              hasBullet={true}
              method={'program-verification-access'}
              submodule={'suivi_verif'}
            />
          }
        </AsideMenuItemWithSub>
      }
      {
        //CheckAccess("raccordement-access") &&
        <AsideMenuItem
          to='/instrument/raccordements'
          icon='/media/icons/duotone/General/Attachment2.svg'
          title='Suivi de raccordement'
          fontIcon='bi-attachment'
          method={'raccordement-access'}
        />
      }
      {/* {
        //CheckAccess("verification-access") &&
        <AsideMenuItem
          to='/instrument/verifications'
          icon='/media/icons/duotone/General/Clipboard.svg'
          title='Suivi de vérification interne'
          fontIcon='bi-clipboard'
          method={"verification-access"}
          submodule={"suivi_verif"}
        />
      } */}
      {
        //CheckAccess("verification-access") &&
        <AsideMenuItemWithSub
          to='/instrument/verifications'
          title='Vérifications / Interventions'
          icon='/media/icons/duotone/General/Clipboard.svg'
          fontIcon='bi-clipboard'
          method={'verification-access'}
          submodule={'suivi_verif'}
        >
          <AsideMenuItem
            to='/instrument/verifications/externe'
            title='Suivi des vérifications externes'
            hasBullet={true}
            method={'verification-externe-access'}
          />
          <AsideMenuItem
            to='/instrument/verifications/interne'
            title='Suivi des vérifications internes'
            hasBullet={true}
            method={'verification-interne-access'}
          />
          <AsideMenuItem
            to='/instrument/verifications/interne/add'
            title='Réaliser une vérification interne'
            hasBullet={true}
            method={'verification-interne-add'}
          />
        </AsideMenuItemWithSub>
      }
      {
        //CheckAccess("qualification-access") &&
        <AsideMenuItem
          to='/instrument/qualifications'
          icon='/media/icons/duotone/General/Shield-check.svg'
          title='Suivi de qualification'
          fontIcon='bi-shield-check'
          method={'qualification-access'}
          submodule={'suivi_qualif'}
        />
      }
      {
        //CheckAccess("person-access") &&
        <AsideMenuItem
          to='/instrument/persons'
          icon='/media/icons/duotone/Communication/Group.svg'
          title='Personnel'
          fontIcon='bi-person'
          method={'person-access'}
        />
      }
      {
        //CheckAccess("service-provider-access") &&
        <AsideMenuItem
          to='/instrument/services-providers'
          icon='/media/icons/duotone/General/Settings-1.svg'
          title='Prestataires'
          fontIcon='bi-bank'
          method={'service-provider-access'}
          submodule={'service_provider'}
        />
      }
      {
        //CheckAccess("setting-access") &&
        <AsideMenuItem
          to='/instrument/settings'
          icon='/media/icons/duotone/General/Settings-2.svg'
          title='Paramétrage'
          fontIcon='bi-sttings'
          method={'setting-access'}
        />
      }

      {/* Section licence de l'app */}
      {/* style={{position: 'absolute', bottom: 0, width: '100%'}} */}
      <div className='menu-item my-10'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
          <div className='mb-0 d-none d-md-block'>
            <p className='text-white m'>
              <span>Licence : </span>
              <span className='text-success'>Active</span>
            </p>
            <p className='text-white'>
              <span>Durée : </span>
              <span className='text-success'>Illimitée</span>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
